import React from "react";

import { Tabs, Tab, TabContent } from "react-bootstrap";

import firstfiveprize from "../../assets/images/raceinfo/21km/8000.png";

import secondsevenprize from "../../assets/images/raceinfo/21km/7000.png";

import thirdfiveprize from "../../assets/images/raceinfo/21km/5000.png";

import tenseven from "../../assets/images/raceinfo/10km/7000.png";

import tenfive from "../../assets/images/raceinfo/10km/5000.png";

import tentwo from "../../assets/images/raceinfo/10km/2000.png";

import fivefive from "../../assets/images/raceinfo/fivekilometer/5000.png";

import fivethree from "../../assets/images/raceinfo/fivekilometer/3000.png";

import fivetwo from "../../assets/images/raceinfo/fivekilometer/2000.png";

const Prizemoney = () => {
  return (
    <div className="prizemoney-wrapper subpages pt-5 pb-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading certer-title"> PRIZE MONEY </h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Tabs
                defaultActiveKey="21"
                transition={false}
                id="noanim-tab-example"
                className="mb-3 roadmap-tabs"
              >
                <Tab eventKey="21" title="21 Kilometers">
                  <TabContent tabTitle="21 Kilometers">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="prize-list-wrapper">
                          <img
                            className="img-fluid"
                            src={firstfiveprize}
                            alt="Before"
                          />

                          <img
                            className="img-fluid"
                            src={secondsevenprize}
                            alt="Before"
                          />

                          <img
                            className="img-fluid"
                            src={thirdfiveprize}
                            alt="Before"
                          />
                        </div>
                      </div>
                    </div>
                  </TabContent>
                </Tab>
                <Tab eventKey="10" title="10 Kilometers">
                <div className="row">
                      <div className="col-md-12">
                        <div className="prize-list-wrapper">
                          <img
                            className="img-fluid"
                            src={tenseven}
                            alt="Before"
                          />

                          <img
                            className="img-fluid"
                            src={tenfive}
                            alt="Before"
                          />

                          <img
                            className="img-fluid"
                            src={tentwo}
                            alt="Before"
                          />
                        </div>
                      </div>
                    </div>

                </Tab>
                <Tab eventKey="5" title="5 Kilometers">

                <div className="row">
                      <div className="col-md-12">
                        <div className="prize-list-wrapper">
                          <img
                            className="img-fluid"
                            src={fivefive}
                            alt="Before"
                          />

                          <img
                            className="img-fluid"
                            src={fivethree}
                            alt="Before"
                          />

                          <img
                            className="img-fluid"
                            src={fivetwo}
                            alt="Before"
                          />
                        </div>
                      </div>
                    </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Prizemoney;
